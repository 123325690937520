import React, { PureComponent } from 'react';
import { ResponsiveContainer, LineChart, Legend, ComposedChart, Bar, ScatterChart, Scatter, LabelList, XAxis, YAxis, AreaChart, Area, Brush, Tooltip, CartesianGrid, Line } from 'recharts';
import data from './E97235.json';

const transformedData = data.map((result) => ({
  ...result,
  date_time: result.date_time.toString().substring(8, result.date_time.length - 3),
  max: parseInt(result['p1_agents-max_D1K581_L1']) - parseInt(result['p1_agents-min_D1K581_L1']),
}));

export default class AgentsQueued extends PureComponent {

  render() {
    return (
      <>
        <div className='chart-header' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <h1>Min/Max/Avg Agents Queued</h1>
        </div>
				<div>
					<p style={{ marginLeft: 70 }}>Number of agents logged into a skill queue (showing only one level),
          waiting for or handling contacts. Might show where you are under/overstaffed.</p>
				</div>
        <ResponsiveContainer width={'99%'} height={500}>
          <ComposedChart
            width={500}
            height={500}
            data={transformedData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='date_time' />
            <YAxis label={{ value: 'Agents', position: 'insideLeft', angle: -90, dy: -10 }} />
            <Tooltip />
            <Legend />

            <Bar dataKey='p1_agents-min_D1K581_L1' stackId='a' fill='#8884d8' fillOpacity='0' />
            <Bar dataKey='max' stackId='a' fill='#8884d8' stroke='#8884d8' />
            <Line type='monotone' dataKey='p1_agents-avg_D1K581_L1' stroke='red' activeDot={{ r: 8 }} />

            <Brush dataKey='date' endIndex={60}>
              <AreaChart>
                <CartesianGrid />
                <YAxis hide domain={['auto', 'auto']} />
                <Area dataKey='p1_agents-avg_D1K581_L1' stroke='red' fill='red' dot={false} />
              </AreaChart>
            </Brush>
          </ComposedChart>
        </ResponsiveContainer>
      </>
    );
  }
}
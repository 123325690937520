import React from 'react';
import { Switch, Route } from 'react-router-dom';
import WaitTime from './wait-time';
import ContactsQueue from './contact-queue';
import OfferedAHT from './aht';
import Occupancy from './occupancy';
import ScheduledOpen from './scheduled-open';
import ASA from './asa';
import Dashboard from './dashboard';
import MeanConcr from './meanconr';
import LoginIdle from './login-idle';
import AgentsQueued from './agents-queued';
import EventTime from './event-time';
import WLContactsQueue from './wl-contact-queue';

const Routes = () => (
	<Switch>
		<Route path='/wait-time'>
			<WaitTime />
		</Route>

		<Route path='/contacts-queue'>
			<ContactsQueue />
		</Route>

		<Route path='/wl-contacts-queue'>
			<WLContactsQueue />
		</Route>

		<Route path='/aht'>
			<OfferedAHT />
		</Route>

		<Route path='/occupancy'>
			<Occupancy />
		</Route>

		<Route path='/asa'>
			<ASA />
		</Route>

		<Route path='/scheduled-open'>
			<ScheduledOpen />
		</Route>

		<Route path='/agents-queued'>
			<AgentsQueued />
		</Route>

		<Route path='/event-time'>
			<EventTime />
		</Route>

		<Route path='/meanconcr'>
			<MeanConcr />
		</Route>

		<Route path='/login-idle'>
			<LoginIdle />
		</Route>

		<Route path='/dashboard'>
			<Dashboard />
		</Route>

		<Route path='/'>
			<Dashboard />
		</Route>
	</Switch>
);

export default Routes;

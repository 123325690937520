import React, { PureComponent } from 'react';

import {
	ComposedChart,
	Bar,
	Line,
	XAxis,
	YAxis,
	Legend,
	AreaChart,
	Area,
	Brush,
	Tooltip,
	CartesianGrid,
	ResponsiveContainer,
} from 'recharts';
import data from './E97235.json';

const transformedData = data.map((result) => ({
	...result,
	date_time: result.date_time.toString().substring(8, result.date_time.length - 3),
	max: parseInt(result['p1_awt-max_D1K581']) - parseInt(result['p1_awt-min_D1K581']),
}));

export default class ASA extends PureComponent {
	render() {
		return (
			<>
				<div className='chart-header' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<h1>ASA</h1>
				</div>
				<div>
					<p style={{ alignItems: 'center' }}>Average Speed of Answer. Shows Offered and Answered Contacts for reference.</p>
				</div>
				<ResponsiveContainer width={'99%'} height={500}>
					<ComposedChart
						width={500}
						height={500}
						data={transformedData}
						margin={{
							top: 5,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey='date_time' />
						<YAxis label={{ value: 'Contacts', position: 'insideLeft', angle: -90, dy: -10 }} />
						<YAxis
							yAxisId='right'
							orientation='right'
							label={{ value: 'Seconds', position: 'insideRight', angle: -90, dy: -10 }}
						/>
						<Tooltip />
						<Legend />

						<Area yAxisId='right' type='monotone' dataKey='p1_asa_T972351' fill='#d3d3d3' stroke='#d3d3d3' />
						<Bar dataKey='p1_offeredContacts_G54_T972351' fill='#8884d8' />
						<Bar dataKey='p1_answeredContacts_G54_T972351' fill='#82ca9d' />
						<Line yAxisId='right' dataKey='p1_asa_T972351' stroke='#ff7300' strokeWidth={3} />

						<Brush dataKey='date' endIndex={60}>
							<AreaChart>
								<CartesianGrid />
								<YAxis hide domain={['auto', 'auto']} />
								<Area dataKey='p1_asa_T972351' stroke='#ff7300' fill='#ff7300' dot={false} />
							</AreaChart>
						</Brush>
					</ComposedChart>
				</ResponsiveContainer>
			</>
		);
	}
}

import React, { PureComponent } from 'react';
import {
	ResponsiveContainer,
	Legend,
	ComposedChart,
	XAxis,
	YAxis,
	AreaChart,
	Area,
	Brush,
	Tooltip,
	CartesianGrid,
} from 'recharts';
import data from './E97235.json';

const transformedData = data.map((result) => ({
	...result,
	date_time: result.date_time.toString().substring(8, result.date_time.length - 3),
	max: parseInt(result['p1_awt-max_D1K581']) - parseInt(result['p1_awt-min_D1K581']),
}));

export default class LoginIdle extends PureComponent {
	render() {
		return (
			<>
				<div className='chart-header' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<h1>Login/Idle</h1>
				</div>
				<div>
					<p style={{ marginLeft: 70 }}>Total Logged-in seconds vs Idle seconds.</p>
				</div>
				<ResponsiveContainer width={'99%'} height={500}>
					<ComposedChart
						width={500}
						height={500}
						data={transformedData}
						margin={{
							top: 100,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey='date_time' />
						<YAxis label='Sec' />
						<Tooltip />
						<Legend />
						<Area type='monotone' dataKey='p1_login_G54' stroke='#82ca9d' fill='#82ca9d' />
						<Area type='monotone' dataKey='p1_idle_G54' stroke='#8884d8' fill='#8884d8' />
						<Brush dataKey='date' endIndex={60}>
							<AreaChart>
								<CartesianGrid />
								<YAxis hide domain={['auto', 'auto']} />
								<Area dataKey='p1_login_G54' stroke='#ff7300' fill='#ff7300' dot={false} />
							</AreaChart>
						</Brush>
					</ComposedChart>
				</ResponsiveContainer>
			</>
		);
	}
}

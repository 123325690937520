import React, { PureComponent } from 'react';

import {
	ComposedChart,
	Bar,
	XAxis,
	YAxis,
	Legend,
	ResponsiveContainer,
	AreaChart,
	Area,
	Brush,
	Tooltip,
	CartesianGrid,
	Line,
} from 'recharts';
import data from './E97235.json';

const transformedData = data.map((result) => ({
	...result,
	date_time: result.date_time.toString().substring(8, result.date_time.length - 3),
	max: parseInt(result['p1_awt-max_D1K581']) - parseInt(result['p1_awt-min_D1K581']),
}));

export default class WaitTime extends PureComponent {
	render() {
		return (
			<>
				<div className='chart-header' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<h1>Min/Max/Avg Wait time</h1>
				</div>
				<div>
					<p style={{ marginLeft: 70 }}>Wait time of contacts to be answered.
					Shows increase in wait time and large variations, and could help identify bottlenecks.</p>
				</div>
				<ResponsiveContainer width={'99%'} height={500}>
					<ComposedChart
						width={500}
						height={500}
						data={transformedData}
						margin={{
							top: 5,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey='date_time' />
						<YAxis label={{ value: 'Seconds', position: 'insideLeft', angle: -90, dy: -10 }} />
						<Tooltip />
						<Legend />

						<Bar dataKey='p1_awt-min_D1K581' stackId='a' fill='#8884d8' fillOpacity='0' />
						<Bar dataKey='max' stackId='a' fill='#8884d8' stroke='#8884d8' />
						<Line type='monotone' dataKey='p1_awt-avg_D1K581' stroke='red' activeDot={{ r: 8 }} />

						<Brush dataKey='date' endIndex={60}>
							<AreaChart>
								<CartesianGrid />
								<YAxis hide domain={['auto', 'auto']} />
								<Area dataKey='p1_awt-avg_D1K581' stroke='red' fill='red' dot={false} />
							</AreaChart>
						</Brush>
					</ComposedChart>
				</ResponsiveContainer>
			</>
		);
	}
}

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import AppNav from './Nav';
import Routes from './Routes';

const App = () => {
	return (
		<Router>
			<AppNav />
			<Routes />
		</Router>
	);
};

export default App;

import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

const AppNav = () => {
	return (
		<Navbar bg='light' expand='lg' className='chart-nav' sticky='top'>
			<Navbar.Brand href='/'>SimMetric</Navbar.Brand>
			<Navbar.Toggle aria-controls='basic-navbar-nav' />
			<Navbar.Collapse id='basic-navbar-nav'>
				<Nav className='mr-auto'>
					<Nav.Link href='/'>Home</Nav.Link>
					<Nav.Link href='/wait-time'>WaitTime</Nav.Link>
					<Nav.Link href='/agents-queued'>Agents Queued</Nav.Link>
					<Nav.Link href='/scheduled-open'>Sched Open</Nav.Link>
					<Nav.Link href='/aht'>AHT</Nav.Link>
					<Nav.Link href='/occupancy'>Occ</Nav.Link>
					<Nav.Link href='/asa'>ASA</Nav.Link>
					<Nav.Link href='/contacts-queue'>Ctx Queued (E)</Nav.Link>
					<Nav.Link href='/wl-contacts-queue'>Ctx Queued (W)</Nav.Link>
					<Nav.Link href='/event-time'>Event Time</Nav.Link>
					<Nav.Link href='/meanconcr'>Mean Concurrency</Nav.Link>
					<Nav.Link href='/login-idle'>LoginIdle</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default AppNav;

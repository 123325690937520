import React, { PureComponent } from 'react';

import { ScatterChart, Scatter, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import data from './E97235.json';

const asaData2 = data.map((result) => ({
	contacts: parseInt(result['p1_contacts-avg_D1K581_L0']) + parseInt(result['p1_contacts-avg_D1K581_L1']),
	asa: result.p1_asa_T972352,
}));

export default class WLContactsQueue extends PureComponent {
	render() {
		return (
			<>
				<div className='chart-header' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<h1>Avg Workload Contacts queued / ASA</h1>
				</div>
				<div>
					<p style={{ marginLeft: 70 }}>Shows the ASA (Average Speed of Answer) in relation to the average number of contacts in queue. 
					The ASA should generally be higher when there are more contacts. This graphs a workload CT.</p>
				</div>
				<ResponsiveContainer width={'99%'} height={500}>
					<ScatterChart
						width={1200}
						height={800}
						margin={{
							top: 20,
							right: 20,
							bottom: 20,
							left: 20,
						}}
					>
						<CartesianGrid />
						<XAxis type='number' dataKey='contacts' name='Contacts' label={{ value: 'Contacts', dy: -10 }} />
						<YAxis
							type='number'
							dataKey='asa'
							name='ASA'
							interval='preserveStartEnd'
							label={{ value: 'ASA', angle: -90, dy: -10 }}
						/>

						<Tooltip cursor={{ strokeDasharray: '3 3' }} />
						<Scatter name='scatter 1' data={asaData2} fill='#8884d8' />
					</ScatterChart>
				</ResponsiveContainer>
			</>
		);
	}
}

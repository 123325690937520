import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import WaitTime from './wait-time';
import ContactsQueue from './contact-queue';
import AgentsQueued from './agents-queued';
import EventTime from './event-time';
import OfferedAHT from './aht';
import Occupancy from './occupancy';
import ScheduledOpen from './scheduled-open';
import ASA from './asa';
import MeanConcr from './meanconr';
import LoginIdle from './login-idle';
import WLContactsQueue from './wl-contact-queue';

class Dashboard extends Component {
	render() {
		return (
			<Container fluid={true}>
				<Row>
					<Col>
						<div className='dash-header'>
							<h1>Dashboard</h1>
						</div>
					</Col>
				</Row>
				<Row>
					<Col sm='12' md='12' lg='6'>
						<div className='card'>
							<WaitTime />
							<a href='/wait-time'>
								<Button className='button' variant='primary'>
									View Full Graph
								</Button>
							</a>
						</div>
					</Col>
					<Col sm='12' md='12' lg='6'>
						<div className='card'>
							<AgentsQueued />
							<a href='/wait-time'>
								<Button className='button' variant='primary'>
									View Full Graph
								</Button>
							</a>
						</div>
					</Col>
					<Col sm='12' md='12' lg='6'>
						<div className='card'>
							<EventTime />
							<a href='/event-time'>
								<Button className='button' variant='primary'>
									View Full Graph
								</Button>
							</a>
						</div>
					</Col>
					<Col sm='12' md='12' lg='6'>
						<div className='card'>
							<ScheduledOpen />
							<a href='/scheduled-open'>
								<Button className='button' variant='primary'>
									View Full Graph
								</Button>
							</a>
						</div>
					</Col>
					<Col sm='12' md='12' lg='6'>
						<div className='card'>
							<OfferedAHT />
							<a href='/aht'>
								<Button className='button' variant='primary'>
									View Full Graph
								</Button>
							</a>
						</div>
					</Col>
					<Col sm='12' md='12' lg='6'>
						<div className='card'>
							<Occupancy />
							<a href='/occupancy'>
								<Button className='button' variant='primary'>
									View Full Graph
								</Button>
							</a>
						</div>
					</Col>
					<Col sm='12' md='12' lg='6'>
						<div className='card'>
							<ASA />
							<a href='/asa'>
								<Button className='button' variant='primary'>
									View Full Graph
								</Button>
							</a>
						</div>
					</Col>
					<Col sm='12' md='12' lg='6'>
						<div className='card'>
							<ContactsQueue />
							<a href='/contacts-queue'>
								<Button className='button' variant='primary'>
									View Full Graph
								</Button>
							</a>
						</div>
					</Col>
					<Col sm='12' md='12' lg='6'>
						<div className='card'>
							<WLContactsQueue />
							<a href='/wl-contacts-queue'>
								<Button className='button' variant='primary'>
									View Full Graph
								</Button>
							</a>
						</div>
					</Col>
					<Col sm='12' md='12' lg='6'>
						<div className='card'>
							<EventTime />
							<a href='/event-time'>
								<Button className='button' variant='primary'>
									View Full Graph
								</Button>
							</a>
						</div>
					</Col>
					<Col sm='12' md='12' lg='6'>
						<div className='card'>
							<MeanConcr />
							<a href='/meanconcr'>
								<Button className='button' variant='primary'>
									View Full Graph
								</Button>
							</a>
						</div>
					</Col>

					<Col sm='12' md='12' lg='6'>
						<div className='card'>
							<LoginIdle />
							<a href='/login-idle'>
								<Button className='button' variant='primary'>
									View Full Graph
								</Button>
							</a>
						</div>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default Dashboard;

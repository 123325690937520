import React, { PureComponent } from 'react';
import {
	ResponsiveContainer,
	Legend,
	ComposedChart,
	Bar,
	XAxis,
	YAxis,
	AreaChart,
	Area,
	Brush,
	Tooltip,
	CartesianGrid,
	Line,
} from 'recharts';
import data from './E97235.json';

const total = (volume, agents) => {
	return isNaN(volume / agents) ? 0 : volume / agents;
};

const transformedData = data.map((result) => ({
	...result,
	date_time: result.date_time.toString().substring(8, result.date_time.length - 3),
	volume: parseInt(result['p1_offeredContacts_G54_T972351']) * parseInt(result['p2_aht_T972351']),
	agents: parseInt(result['p1_login_G54_T972351']) / 1800,
	call_volume_per_agent: total(
		parseInt(result['p1_offeredContacts_G54_T972351']) * parseInt(result['p2_aht_T972351']),
		parseInt(result['p1_login_G54_T972351']) / 1800
	),
}));

export default class MeanConcr extends PureComponent {
	render() {
		return (
			<>
				<div className='chart-header' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<h1>Mean Concurrency</h1>
				</div>
				<div>
					<p style={{ marginLeft: 70 }}>Mean Concurrency - shows agents handling simultaneous contacts.
					Plotted against the average workload per agent [Calls Offered x AHT] / [Logged in Agents].
					The higher the workload, the more concurrency may occur.</p>
				</div>
				<ResponsiveContainer width={'99%'} height={500}>
					<ComposedChart
						width={500}
						height={500}
						data={transformedData}
						margin={{
							top: 5,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey='date_time' label={{ value: 'Concurrency', position: 'insideLeft', angle: -90, dy: -20 }} />
						<YAxis />
						<YAxis
							yAxisId='right'
							type='number'
							orientation='right'
							label={{ value: 'Contacts', position: 'insideRight', angle: -90, dy: -10 }}
						/>
						<Tooltip />
						<Legend />
						<Bar yAxisId='right' dataKey='call_volume_per_agent' fill='#8884d8' stroke='#d3d3d3' />
						<Line
							type='monotone'
							dataKey='p1_mean-concurrency_T972354'
							stroke='red'
							strokeWidth='3'
							dot={false}
							activeDot={{ r: 8 }}
						/>

						<Brush dataKey='date' endIndex={60}>
							<AreaChart>
								<CartesianGrid />
								<YAxis hide domain={['auto', 'auto']} />
								<Area dataKey='p1_mean-concurrency_T972354' stroke='#ff7300' fill='#ff7300' dot={false} />
							</AreaChart>
						</Brush>
					</ComposedChart>
				</ResponsiveContainer>
			</>
		);
	}
}

import React, { PureComponent } from 'react';

import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Legend,
	ResponsiveContainer,
	AreaChart,
	Area,
	Brush,
	Tooltip,
	CartesianGrid,
} from 'recharts';
import data from './E97235.json';

const transformedData = data.map((result) => ({
	...result,
	date_time: result.date_time.toString().substring(8, result.date_time.length - 3),
	max: parseInt(result['p1_awt-max_D1K581']) - parseInt(result['p1_awt-min_D1K581']),
}));

export default class ScheduledOpen extends PureComponent {
	render() {
		return (
			<>
				<div className='chart-header' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<h1>Schedule Open / Staff</h1>
				</div>
				<div>
					<p style={{ marginLeft: 70 }}>Stacked scheduled open for MUs, and compare to total for the CT.
					The two should match but can be slightly off b/c of calls going into the next interval.</p>
				</div>
				<ResponsiveContainer width={'99%'} height={500}>
					<BarChart
						width={500}
						height={300}
						data={transformedData}
						margin={{
							top: 5,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey='date_time' />
						<YAxis label={{ value: 'Agents', position: 'insideLeft', angle: -90, dy: -10 }} />
						<Tooltip />
						<Legend />

						<Bar dataKey='p1_muSchedOpen_M972351_T972351' stackId='a' fill='#8884d8' />
						<Bar dataKey='p1_muSchedOpen_M972352_T972351' stackId='a' fill='#ffc658' />
						<Bar dataKey='p1_totalStaff_T972351' stackId='b' fill='#82ca9d' />

						<Brush dataKey='date' endIndex={60}>
							<AreaChart>
								<CartesianGrid />
								<YAxis hide domain={['auto', 'auto']} />
								<Area dataKey='p1_awt-avg_D1K581' stroke='red' fill='red' dot={false} />
							</AreaChart>
						</Brush>
					</BarChart>
				</ResponsiveContainer>
			</>
		);
	}
}
